@import '../../colors.scss';

.sortToggle {
  display: inline-block;
  
  button {
    background-color: palette('secondary');
    border: none;
    border-radius: 4px;
    color: palette('text');
    text-decoration-color: $basic_blue;
    &:focus, &:active {
      border: none;
    }
  }
  .dropdownMenu {
    background-color: palette('primary');
    color: palette('text');
    .dropdownItem {
      color: palette('text');
      &.active {
        background-color: palette('primary');
      }
      &:hover {
        background-color: $basic_blue;
        color: palette('text');
      }
    }
  }
}
