@import '../colors.scss';

.trophy {
  width: 100%;
}

.noTrophy {
  filter: alpha(opacity=40);
  height: 70px;
  margin: 30px;
  opacity: .4;
  zoom: 1;
}
