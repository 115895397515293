@import '../../colors.scss';
@import '~bootstrap/scss/functions';
@import '~bootstrap/scss/variables';
@import '~bootstrap/scss/mixins';

.runnerList {
  display: contents;
  .runnerListRow {
    border-color: palette('secondary');
    border-style: solid;
    border-width: .063rem 0;
    display: flex;
    margin: 0 .625rem;
    padding: .313rem 1.875rem;
    position: relative;
    &.scratched {
      background: repeating-linear-gradient(
        -45deg,
        $dark_grey,
        $dusty_grey .313rem,
        palette('background') .313rem,
        palette('background') 1.25rem
      );
    }
    .scratchedOverlay {
      background-color: palette('text');
      bottom: 0;
      color: $basic_red;
      font-size: 1.5em;
      font-weight: bold;
      height: 100%;
      left: 40%;
      line-height: 4.375rem;
      opacity: .8;
      position: absolute;
      right: 40%;
      text-align: center;
      top: 0;
      width: 20%;
      z-index: 4;
    }
    .disabledOverlay {
      background-color: palette('background');
      bottom: 0;
      height: 100%;
      left: 0;
      opacity: .7;
      position: absolute;
      right: 0;
      top: 0;
      width: 100%;
      z-index: 2;
    }
    &.selected {
      background-color: palette('primary');
    }
    .raceCardInfo {
      display: contents;
      .postPosition {
        display: inline-block;
        font-weight: bold;
        height: 2.5rem;
        position: relative;
        width: 2.5rem;
        @include media-breakpoint-down(lg) {
          height: 2rem;
          width: 2rem;
        }
        .postText {
          font-size: .688rem;
          margin: 0 auto;
          padding-top: .125rem;
          text-align: center;
          width: fit-content;
          @include media-breakpoint-down(lg) {
            font-size: .4rem;
          }
          .postNumber {
            font-size: 1.25rem;
            line-height: 1.25rem;
            @include media-breakpoint-down(lg) {
              font-size: 1rem;
              line-height: 1rem;
            }
          }
        }
      }
    }
    .displayName {
      align-self: center;
      display: flex;
      padding-left: 1.25rem;
      text-transform: uppercase;
      vertical-align: middle;
      .horseName {
        font-weight: bold;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
      }
      .selectName {
        color: $dim-light-blue;
        font-style: italic;
        justify-content: center;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
      }
    }
    .selectionWrapper {
      display: block;
      padding: 0 .313rem;
      .selectionStatus {
        border-radius: 50%;
        cursor: pointer;
        font-size: 3em;
        height: 2.5rem;
        line-height: 2.5rem;
        position: relative;
        text-align: center;
        width: 2.5rem;
        @include media-breakpoint-down(lg) {
          font-size: 2em;
          font-weight: 600;
          height: 2rem;
          line-height: 2.8rem;
          width: 2rem;
        }
        &.selected {
          background-color: palette('deepBackground');
          border: .1rem solid $dim-red;
          border-color: $dim-red;
          color: $dim-red;
          &::after {
            content: '_';
            height: 1.875rem;
            left: .438rem;
            position: absolute;
            top: -1.188rem;
            width: .938rem;
            &:hover {
              border-color: palette('background');
            }
          }
        }
        &.notSelected {
          background-color: palette('background');
          border: .1rem solid $dim-blue;
          border-color: $dim-blue;
          color: $dim-blue;
          cursor: default;
          &::after {
            content: '_';
            height: 1.875rem;
            left: .438rem;
            position: absolute;
            top: -1.188rem;
            width: .938rem;
            &:hover {
              border-color: palette('background');
            }
          }
        }
        &.disabled {
          border-color: $dusty_grey;
          color: $dusty_grey;
          cursor: not-allowed;
          display: none;
          &:hover {
            background-color: palette('background');
          }
        }
      }
    }
  }
}
