@import './colors.scss';
@import 'toastr';

body {
  background-color: palette('background');
  font-family: -apple-system,
    BlinkMacSystemFont,
    'Segoe UI',
    'Roboto',
    'Oxygen',
    'Ubuntu',
    'Cantarell',
    'Fira Sans',
    'Droid Sans',
    'Helvetica Neue',
    sans-serif;
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
  height: 100vh;
  margin: 0;
  padding: 0;
}

code {
  font-family: 'Montserrat', sans-serif;
}
