@import '../../colors.scss';
@import '../../variables.scss';

$my-stable-height: calc(
  100vh -
  #{$top-nav-height} -
  #{$bottom-footer-height} -
  #{$top-padding-height});
$new-game-btn-height: 2vh;

.myStables {
  background-color: palette('deepBackground');
  color: palette('text');
  min-height: $my-stable-height;
  padding: $top-padding-height 2rem 0 2rem;
  .contentCell {
    background-color: palette('background');
    border-radius: .625rem;
    margin: 1rem;
    .sdLogoRow {
      justify-content: center;
      padding: 2em;
      .sdLogoTag {
        width: 80%;
      }
    }
    .todayFutureContainer {
      min-height: calc(
        #{$my-stable-height} -
        #{$new-game-btn-height});
    }
    .newGameBtn {
      height: $new-game-btn-height;
      margin: 0 2rem 1rem;
    }
  }
}
