@import '../colors.scss';

.navItem {
  align-items: center;
  color: palette('text');
  display: inline-block;
  font-size: 12px;
  height: 100%;
  margin-bottom: -10px;
  padding: 15px 25px 8px;

  &:hover {
    color: palette('text');
    text-decoration: none;
  }
}
.navItemActive {
  background-color: palette('primary');
  border: 2px solid palette('gray');
}
.navItemDisabled {
  align-items: center;
  color: $dusty_grey;
  display: inline-block;
  font-size: 12px;
  height: 100%;
  margin-bottom: -10px;
  padding: 15px 25px 8px;
  pointer-events: none;

  &:hover {
    color: $dusty_grey;
    cursor: default;
    text-decoration: none;
  }
}
