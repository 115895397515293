@import '../../colors.scss';

.titleContainer {
  font-weight: bold;
  text-align: center;
  .subTitle {
    font-size: .7em;
  }
}

.bodyContainer {
  display: grid;
  grid-template-columns: 30% auto;
  padding: 20px;
  width: 100%;
  .race {
    color: palette('green');
    font-weight: bold;
  }
  .raceName {
    font-weight: bold;
  }
  .raceInfo {
    font-size: .8em;
    padding-bottom: 10px;
  }
}
