@import '../../colors.scss';
@import '../../variables.scss';

.header {
  align-items: center;
  background-color: $prussian_blue;
  border: none;
  border-radius: 3.125rem;
  box-shadow: none;
  color: palette('green');
  display: grid;
  font-size: .8rem;
  font-weight: bold;
  grid-template-rows: 1.875rem;
  margin: .125rem 0;
  text-align: center;
  text-transform: uppercase;
}
