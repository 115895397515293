.deleteHeader {
  border-bottom: none !important;
}

.deleteBody {
  font-size: 20px;
  font-weight: bold;
  margin-bottom: 20px;
  padding: 0 20px 20px;
  text-align: center;
  width: 100%;
}
