@import '../../colors.scss';

.profileSummary {
  color: palette('text');
  .top {
    padding-top: 2.188rem;
  }
  .stableName {
    font-size: 1.5rem;
    font-weight: bold;
    text-transform: uppercase;
  }
  .followers {
    font-size: .75rem;
  }
  .text {
    font-size: .625rem;
    text-align: center;
  }
  .numbers {
    font-size: 1.7rem;
    font-weight: bold;
    text-align: center;
  }
  .numberPoints {
    font-size: 2.25rem;
    font-weight: bold;
    margin-top: -.875rem;
    text-align: center;
  }
  .addImage {
    padding: 1.25rem 0;
    .addStatus {
      background-color: palette('primary');
      border: .063rem solid palette('text');
      border-radius: 50%;
      color: palette('text');
      cursor: pointer;
      font-size: 2.5em;
      height: 4.688rem;
      line-height: 3rem;
      margin: 0 auto;
      position: relative;
      text-align: center;
      width: 4.688rem;
      &:hover {
        background-color: palette('text');
        border-color: palette('background');
        color: palette('background');
      }
      &::after {
        content: '+';
        height: 1.875rem;
        left: 1.5rem;
        position: absolute;
        top: .75rem;
        width: .938rem;
        &:hover {
          border-color: palette('background');
        }
      }
    }
  }
}
