@import '../colors.scss';

.news {
  color: palette('text');
  padding: .7rem;
  .newsHeader {
    background-color: palette('primary');
    border-radius: 1.25rem;
    color: palette('green');
    font-weight: bold;
    text-align: center;
    text-transform: uppercase;
  }
  .newsWrapper {
    .newsImageCol {
      .newsImage {
        margin-top: .25rem;
        width: 100%;
      }
    }
    .newsItem {
      padding: auto .5rem;
      .newsTitle {
        font-weight: bold;
      }
      .newsText {
        font-size: .75rem;
      }
      .newsLink a {
        color: palette('text');
        font-size: .75rem;
        font-weight: bold;
        text-transform: uppercase;
      }
    }
  }
}
