@import '../../colors.scss';
@import '../../variables.scss';
@import '~bootstrap/scss/functions';
@import '~bootstrap/scss/variables';
@import '~bootstrap/scss/mixins';

$track-header-height: 5vh;

.selectionWrapper {
  color: palette('text');
  a {
    color: palette('text');
  }
  .tabWrapper {
    border-bottom: 1px solid palette('text');
    box-sizing: border-box;
    
    .tab {
      background-color: palette('background');
      color: palette('text');
      cursor: pointer;
      font-size: .8em;
      padding: 15px 25px;
      text-align: center;
      text-transform: uppercase;
      &.active {
        background-color: palette('primary');
        border: 1px solid palette('text');
        border-bottom: none;
      }
    }
  }
  .raceCard {
    .trackHeader {
      >div {
        margin-top: 1rem;
      }
      .trackName {
        color: palette('green');
        font-size: 1em;
        font-weight: bold;
        text-align: center;
        text-transform: uppercase;
      }
      .trackInfo {
        font-weight: bold;
        text-align: center;
      }
      
      .gameStart {
        color: palette('green');
        font-size: .75em;
        font-weight: bold;
        text-align: center;
        text-transform: uppercase;
        vertical-align: middle;
        .startTime {
          font-size: 1.75em;
        }
      }
    }
    .raceWrapper {
      height: calc(
        100vh -
        #{$top-nav-height} - #{$top-padding-height} - #{$date-nav-height} -
        #{$track-header-height} - #{$bottom-footer-height} - 10vh );
      margin-top: 1rem;
      overflow: scroll;
      @include media-breakpoint-down (lg) {
        height: calc(
          100vh -
          #{$top-nav-height} - #{$top-padding-height} - #{$date-nav-height} -
          #{$track-header-height} - #{$bottom-footer-height} - 15vh );
      }
    }
  }
  .sortWrapper {
    display: inline-block;
  }
}

@media screen and (min-width:990px) {
  .selectionWrapper {
    .raceCard {
      .trackHeader {
        .trackName {
          text-align: right;
        }
        .trackInfo {
          margin-left: -25px;
          text-align: left;
        }
      }
    }
  }
}
