@import '../colors.scss';

.SDnavbar {
  background-color: palette('primary') !important;
  padding: 0 !important;
  width: 100%;
  .navLogo {
    padding-left: 10px;
    width: 200px;
  }
  .navNotifications {
    display: flex;
    justify-content: flex-end;
  }
}
