@import '../colors.scss';
@import '../variables.scss';

.contentGrid {
  background-color: palette('deepBackground');
  color: palette('text');
  margin: auto 2rem;
  min-height: calc(100vh - #{$top-nav-height});
  padding-top: $top-padding-height;
  .contentCell {
    background-color: palette('background');
    border-radius: 10px;
    margin: 0 .5rem;
    .sdLogoTag {
      padding: 4rem 8rem;
      width: 100%;
    }
  }
}
