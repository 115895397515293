@import '../../colors.scss';

.myStableInfo {
  background-color: palette('colBackground');
  border: .063rem solid palette('green');
  border-radius: 3.125rem;
  color: palette('text');
  font-size: 1.25em;
  font-weight: bolder;
  text-transform: uppercase;
  @media (max-width: 1350px) {
    font-size: .8em;
  }
  .horseQuantity {
    justify-content: center;
  }
  .bottomLabel {
    font-size: .5em;
    justify-content: center;
    margin-top: -.375rem;
    text-align: center;
    @media (max-width: 1550px) {
      font-size: .4em;
    }
    @media (max-width: 1350px) {
      font-size: .25em;
    }
    @media (max-width: 1270px) {
      font-size: .2em;
    }
  }
  .salaryRemaining {
    font-size: 1.5em;
    justify-content: center;
  }
}
