@import '../colors.scss';
@import '../variables.scss';

.trackBackground {
  border: 1px solid $basic_yellow;
  fill: none;
  stroke: rgba(175, 175, 175, .171);
  width: 440px;
}
.pathTrack {
  fill: none;
  stroke: $basic_blue;
  stroke-linecap: butt;
}
@keyframes dash {
  to {
    stroke-dashoffset: 0;
  }
}
.chartText {
  fill: $basic_white;
  transform: translateY(.25em);
}
.chartNumber {
  font-size: 2.5em;
  text-anchor: middle;
  transform: translateY(.25em);
}
.chartLabel {
  font-size: 2em;
  text-anchor: middle;
  transform: translateY(1.5em);
}
