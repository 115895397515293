$basic_black: #000000;
$basic_black_alpha: #00000030;
$basic_white: #ffffff;
$basic_green: #00cc66;
$basic_blue: #017cff;
$basic_grey: #cccccc;
$basic_red: #ff0000;
$basic_yellow: #ffff00;
$dusty_grey: #999999;
$mercury: #e2e2e2;
$wild_sand: #f5f5f5;
$dark_grey: #333333;
$lighter_grey: #dddddd;
$lighter_grey_alpha: #cfdddddd;
$chathams_blue: #1c4076;
$cloud_burst: #212d45;
$cloud_burst_alpha: #80212d45;
$black_pearl: #091530;
$black_pearl_alpha: #99091530;
$black_pearl_alpha_dark: #dd091530;
$downriver: #091b40;
$prussian_blue: #001957;
$darker_prussian_blue: #03174b;
$blue_zodiac: #0d1d3d;
$oxford_blue: #3a4558;
$green: #00cc66;
$blue: #0b2347;
$dim-blue: #011e67;
$dim-light-blue: #013bcc;
$dim-red: #ff000078;
$yellow: #fff952;

.cloth_color_1 {
  background-color: #ff0217;
  color: $basic_white;
}
.cloth_color_2 {
  background-color: $basic_white;
  color: $basic_black;
}
.cloth_color_3 {
  background-color: #0042f5;
  color: $basic_white;
}
.cloth_color_4 {
  background-color: #fff952;
  color: $basic_black;
}
.cloth_color_5 {
  background-color: #008024;
  color: $basic_white;
}
.cloth_color_6 {
  background-color: $basic_black;
  color: $basic_white;
}
.cloth_color_7 {
  background-color: #ff6626;
  color: $basic_black;
}
.cloth_color_8 {
  background-color: #ff99cc;
  color: $basic_black;
}
.cloth_color_9 {
  background-color: #00fffe;
  color: $basic_black;
}
.cloth_color_10 {
  background-color: #801c80;
  color: $basic_white;
}
.cloth_color_11 {
  background-color: #959595;
  color: $basic_white;
}
.cloth_color_12 {
  background-color: #98cc40;
  color: $basic_black;
}
.cloth_color_13 {
  background-color: #281b03;
  color: $basic_white;
}
.cloth_color_14 {
  background-color: #5e0909;
  color: $wild_sand;
}
.cloth_color_15 {
  background-color: #ddd9c3;
  color: $basic_black;
}
.cloth_color_16 {
  background-color: #99ccfb;
  color: $basic_black;
}
.cloth_color_17 {
  background-color: #0a1d30;
  color: $basic_white;
}
.cloth_color_18 {
  background-color: #022206;
  color: $basic_white;
}
.cloth_color_19 {
  background-color: #003399;
  color: $basic_white;
}
.cloth_color_20 {
  background-color: #fe3399;
  color: $basic_black;
}
.cloth_color_21 {
  background-color: #b4b1d7;
  color: $basic_black;
}
.cloth_color_22 {
  background-color: #003667;
  color: $basic_white;
}
.cloth_color_23 {
  background-color: #bcd6cd;
  color: $basic_black;
}
.cloth_color_24 {
  background-color: #302510;
  color: $basic_white;
}
.cloth_color_default {
  background-color: #d8ffff;
  color: $basic_black;
}
.cloth_color_none {
  background-color: $dim-blue;
}

.risk_color_high {
  fill: #ff0217;
}
.risk_color_medium {
  fill: #fff952;
}
.risk_color_low {
  fill: $green;
}
.risk_color_default {
  fill: #dddddd;
}

$color_palette: (
  'background': $downriver,
  'deepBackground': $blue,
  'primary': $prussian_blue,
  'darker_primary': $darker_prussian_blue,
  'secondary': $chathams_blue,
  'accent': $blue_zodiac,
  'text': $basic_white,
  'green': $basic_green,
  'gray': $dusty_grey,
  'error': $basic_red,
  'yellow': $basic_yellow,
  'pillBlue': $dim-blue,
  'colBackground': $black_pearl,
);

@function palette($key: 'color' ) {
  @return map-get($color_palette, $key);
}
