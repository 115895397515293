@import '../colors.scss';

.joinTxt {
  color: palette('green');
  margin: 15px 0 20px;
}

.whiteTxt {
  color: palette('text');
}

.defaultBackground {
  background-position: center;
  background-repeat: no-repeat;
  margin: 0;
  min-height: 100%;
  min-width: 100%;
  overflow: hidden;
  position: absolute;
  text-align: center;
  .pagecontent {
    .logoWrapper {
      background-image: url('../assets/images/stableduel-logo-with-tag.png');
      background-origin: content-box;
      background-position: center;
      background-repeat: no-repeat;
      background-size: contain;
      height: 190px;
      margin: 5% auto 20px;
      position: relative;
      width: 40%;
      z-index: 100;
    }
    .formWrapper {
      display: grid;
      grid-template-columns: 50%;
      justify-content: center;
      margin: 0 auto;
      position: relative;
      text-align: center;
    }
    
    .switchWrapper {
      margin: 0 auto;
      position: relative;
      width: 50%;
      .linkTxt {
        color: palette('text');
        font-size: 20px;
      }
    }
    
    .btnWidth {
      width: 50%;
    }
    
    .row {
      margin-top: 80px;
    }
  }
}
