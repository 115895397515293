.raceHeader {
  padding: 5px 25px;
  text-align: center;
  .nameHeader {
    text-align: left;
  }
  .salary {
    padding-right: 2rem;
    text-align: right;
  }
}
