@import '../../colors.scss';
@import '../../variables.scss';

.contentColumn {
  color: palette('text');
  padding: 0 0 0 20px;
  width: 100%;
  .contentCell {
    background-color: palette('background');
    border-radius: 10px;
    padding: 2%;
    width: 100%;
    @media (max-height: 820px) {
      padding: 3%;
    }
  }

  @media (max-width: 768px) {
    display: block;
  }
}
