@import '../../colors.scss';

.inputWrapper {
  margin-bottom: 0;
  .textInput {
    background-color: rgba(0, 0, 63, .6);
    border: .5px solid palette('gray');
    border-radius: 0;
    color: palette('text');
    padding: .375rem .75rem;
    -webkit-text-fill-color: palette('text') !important;
    &:focus {
      background-color: rgba(0, 0, 63, .6);
      color: palette('text');
    }
    &:-webkit-autofill,
    &:-webkit-autofill:hover,
    &:-webkit-autofill:focus,
    &:-webkit-autofill:active {
      transition: background-color 5000s ease-in-out 0s;
    }
  }
  .search {
    background-color: palette('pillBlue');
    border: 0;
    border-radius: 3.125rem;
    box-shadow: none;
    color: palette('text');
    font-size: .9rem;
    outline: none;
    padding-left: 3rem;
    -webkit-text-fill-color: palette('text') !important;
    &:focus {
      background-color: palette('pillBlue');
      color: palette('text');
    }
    &:-webkit-autofill,
    &:-webkit-autofill:hover,
    &:-webkit-autofill:focus,
    &:-webkit-autofill:active {
      transition: background-color 5000s ease-in-out 0s;
    }
  }

  .inValid {
    border: .5px solid palette('error');
    &:focus {
      border: .5px solid palette('error');
      color: palette('text');
    }
  }

  .errorText {
    color: palette('error');
    font-size: 14px;
    text-align: left;
  }
}
