@import '../../colors.scss';
@import '../../variables.scss';
@import '~bootstrap/scss/functions';
@import '~bootstrap/scss/variables';
@import '~bootstrap/scss/mixins';

.gameSupport {
  height: 90vh;
  margin-top: $top-nav-height + 3vh;
  @include media-breakpoint-down (sm) {
    margin-top: $date-nav-height + 3vh;
  }
  .mainHeader {
    background-color: palette('pillBlue');
    border-radius: 3.125rem;
    color: palette('green');
    font-size: .9rem;
    font-weight: bold;
    padding: .1rem 0;
  }
  .contentRow {
    min-height: 90%;
    .col {
      background-color: palette('colBackground');
      border-radius: 1.25rem;
      .FAQ {
        background-color: palette('green');
        border-radius: 3.125rem;
        color: palette('colBackground');
        font-size: .8rem;
        font-weight: bold;
      }
      .icon {
        transform: translate(130%, -175%);
      }
      .searchBar {
        padding: 0 2.5rem 0 1rem;
      }
      .headerTxt {
        color: palette('green');
        font-size: 1.5rem;
        font-weight: bold;
        margin-left: 1.8rem;
      }
      .txt {
        color: palette('text');
        font-size: .65rem;
        @media (min-width: 1400px) {
          font-size: .8rem;
        }
      }
      .accordianBtn {
        background-color: palette('pillBlue');
        border: .5px solid palette('gray');
        border-radius: 50px;
        color: palette('green');
        font-size: .8rem;
        font-weight: bold;
        outline: 0;
      }
      .itemQs {
        color: palette('green');
        font-size: .75rem;
      }
      .contactUs {
        background-color: palette('green');
        border-radius: 3.125rem;
        color: palette('colBackground');
        font-size: .8rem;
        font-weight: bold;
      }
      ul {
        list-style: none; /* Remove default bullets */
      }
      ul li::before {
        color: palette('text'); /* Change the color */
        content: '\2022'; /* Add content: \2022 is the CSS Code/unicode for a bullet */
        display: inline-block; /* Needed to add space between the bullet and the text */
        font-weight: 900; /* If you want it to be bold */
        margin-left: -1em; /* Also needed for space (tweak if needed) */
        width: 1em; /* Also needed for space (tweak if needed) */
      }
    }
  }
}
