@import '../../colors.scss';
@import '../../variables.scss';

.leaderboard {
  background-color: palette('background');
  color: palette('text');
  font-size: .75rem;
  font-weight: bold;
  padding-top: 1.25rem;
  width: 100%;
  @media (max-width: 992px) {
    font-size: 1rem;
  }

  .widgetTitle {
    font-size: .9rem;
    margin: 1.25rem 0;
    text-align: center;
    @media (max-width: 992px) {
      font-size: 1.5rem;
    }
  }

  .myStableInfo {
    background-color: $prussian_blue;
    border-radius: 30px;
    color: palette('green');
    height: 50px;
    text-transform: uppercase;
    @media (max-width: 992px) {
      padding-left: 5rem;
    }
    .myRank {
      margin-top: .125rem;
      padding-left: 1.8rem;
    }
    .image {
      height: 1.25rem;
      transform: translate(-15%, 0);
      @media (max-width: 992px) {
        height: 1.5rem;
      }
    }
    .myStableName {
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
    }
    .myPoints {
      color: palette('text');
      font-weight: normal;
      padding-left: 1rem;
    }
  }

  .tableHeadings {
    font-size: .5rem;
    @media (max-width: 992px) {
      font-size: .75rem;
      padding-left: 5rem;
    }
  }
  .boardObjects {
    overflow-y: scroll;
    @media (max-width: 992px) {
      padding-left: 5rem;
    }
    .userRow {
      margin: .313rem;
      .rank {
        padding-left: 2.1rem;
      }
      &:nth-child(-n + 3) {
        .rank {
          color: palette('green');
          padding-left: 2.1rem;
        }
        .topThree {
          color: palette('primary');
        }
      }
      .stableName {
        font-size: .7rem;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
        @media (max-width: 992px) {
          font-size: .9rem;
        }
      }
      .boardPoints {
        font-weight: normal;
      }
    }
    .myStable {
      background-color: palette('green');
      border-radius: 1.875rem;
      color: palette('primary');
    }
  }
}
