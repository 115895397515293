@import '../../colors.scss';
@import '../../variables.scss';
@import '~bootstrap/scss/functions';
@import '~bootstrap/scss/variables';
@import '~bootstrap/scss/mixins';

.editStableContainer {
  background-color: palette('primary');
  border: .125rem solid palette('secondary');
  border-radius: .625rem;
  padding: 1rem;
  @include media-breakpoint-down (lg) {
    padding-bottom: 2.5rem;
  }
  .title {
    color: palette('green');
    font-weight: bold;
    justify-content: center;
    padding: 1.125rem;
    text-align: center;
    .image {
      height: 1.563rem;
      padding-right: .938rem;
    }
  }
  .buttonsRow {
    .deleteButton {
      background-color: palette('colBackground');
      border: .063rem solid $basic_red;
      border-radius: 50%;
      color: palette('text');
      cursor: pointer;
      font-size: .625em;
      height: 4.5rem;
      position: relative;
      text-align: center;
      width: 4.5rem;
      &:hover {
        border-color: palette('background');
      }
      @media (max-width: 1350px) {
        font-size: .4em;
        height: 3.125rem;
        width: 3.125rem;
      }
      &::after {
        content: 'DELETE STABLE';
        left: 1.5em;
        position: absolute;
        top: 1.2rem;
        width: 1rem;
        &:hover {
          border-color: palette('background');
        }
        @media (max-width: 1350px) {
          left: .4rem;
          top: .5rem;
        }
      }
    }
  }
}
