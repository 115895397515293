@import '../colors.scss';
@import '../variables.scss';

$my-stable-height: calc(
  100vh -
  #{$top-nav-height} -
  #{$bottom-footer-height} -
  #{$top-padding-height});

.myProfile {
  background-color: palette('deepBackground');
  color: palette('text');
  min-height: $my-stable-height;
  padding: $top-padding-height 2rem 0 2rem;
  .contentNoCell {
    border-radius: .625rem;
    .trackTrophy {
      margin: 2.25rem 0;
    }
    .progressBar {
      margin: 1.25rem 0 5rem;
    }
    .sdLogoRow {
      justify-content: center;
      padding: 2em 0;
      .sdLogoTag {
        width: 60%;
      }
    }
    .newGameBtn {
      margin: 0 0 6rem;
      padding: 1.125rem 0 !important;
    }
  }
  .contentCell {
    background-color: palette('background');
    border-radius: .625rem;
    margin: 1rem;
    .profile {
      background-color: palette('deepBackground');
      padding-bottom: 2rem;
    }
  }
}
