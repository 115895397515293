@import '../../colors.scss';

.race {
  .raceInfo {
    background-color: palette('primary');
    border-radius: 20px;
    font-size: 1rem;
    margin: .75rem 0;
    padding: 5px 28px;
    text-transform: uppercase;
    vertical-align: middle;
    .raceIndex {
      font-size: 1.2rem;
      font-weight: bold;
    }
  }
}
