@import '../colors.scss';

.btnLink {
  background-color: palette('green');
  border: .125rem solid palette('green');
  border-radius: 3.125rem;
  box-shadow: none;
  color: palette('primary');
  cursor: pointer;
  padding: .625rem 2.813rem;
  text-decoration: none;
  &.disabledBtn {
    opacity: .4;
    pointer-events: none;
  }
  &:hover {
    color: palette('text');
    text-decoration: none;
  }
  &.outline {
    background-color: palette('primary');
    border: .125rem solid palette('green');
    color: palette('green');
    cursor: pointer;
    padding: .625rem 2.813rem;
    width: 100%;
    &:hover {
      background-color: palette('green');
      color: palette('primary');
      text-decoration: none;
    }
  }
  &.large {
    width: 100%;
  }
  &.outline-large {
    background-color: $blue;
    border: .125rem solid palette('green');
    color: palette('green');
    &:hover {
      background-color: palette('green');
      color: $blue;
      text-decoration: none;
    }
  }
  &.outline-trackWidget {
    background-color: $blue;
    border: .125rem solid palette('green');
    color: palette('green');
    display: flex;
    justify-content: center;
    padding: .19rem 0;
    text-decoration: none !important;
    width: 100%;
    &:hover {
      background-color: palette('green');
      color: $blue;
    }
  }
  &.outline-myProfile {
    background-color: $blue;
    border: .125rem solid palette('green');
    color: palette('green');
    display: flex;
    justify-content: center;
    padding: .75rem 0;
    text-decoration: none !important;
    width: 100%;
    &:hover {
      background-color: palette('green');
      color: $blue;
    }
  }
  &.whiteOutlineLarge {
    background-color: palette('primary');
    border: .063rem solid rgba(255, 255, 255, .4);
    color: palette('text');
    font-size: .75rem;
    font-weight: bold;
    width: 100%;
    &:hover {
      background-color: palette('text');
      color: palette('primary');
    }
  }
  &.greenSkinny {
    border: .063rem solid palette('green');
    display: flex;
    font-size: .75rem;
    font-weight: bold;
    justify-content: center;
    margin: .313rem;
    padding: .25rem 0;
    white-space: nowrap;
    width: 100%;
    &:hover {
      background-color: palette('text');
      color: palette('primary');
    }
  }
  &.whiteOutlineBlue {
    background-color: palette('deepBackground');
    border: .063rem solid palette('text');
    color: palette('text');
    display: flex;
    font-size: .75rem;
    justify-content: center;
    margin: .313rem;
    white-space: nowrap;
    width: 100%;
    &:hover {
      background-color: palette('text');
      color: palette('deepBackground');
    }
  }
  &.greenVerySkinny {
    border: .063rem solid palette('green');
    display: flex;
    font-size: .75rem;
    font-weight: bold;
    justify-content: center;
    padding: .25rem 0;
    width: 100%;
    &:hover {
      background-color: palette('text');
      color: palette('primary');
    }
  }
}
