@import '../../colors.scss';

.wrapper {
  color: palette('text');
  text-align: center;
  width: 100%;
  .rowWrapper {
    height: 60px;
    ::placeholder { /* Chrome, Firefox, Opera, Safari 10.1+ */
      color: palette('text');
      opacity: 1; /* Firefox */
    }
    .col {
      padding-left: 5px;
      padding-right: 5px;
    }
  }
  .margin {
    margin-bottom: 10px;
  }
  .forgetPass {
    color: palette('text');
    cursor: pointer;
    height: 50px;
    .linkTxt {
      color: palette('text');
    }
  }
  .btn {
    margin-top: 40px;
  }
  .errorTxt {
    color: palette('error');
  }
}

i {
  position: absolute;
  right: 5%;
  top: 15%;
}

i:hover {
  color: palette('green');
  cursor: pointer;
}
