@import '../../colors.scss';

.cmsContainer {
  background-color: $downriver;
  color: palette('text');
  min-height: 90vh;
  padding: 60px 20px 0;
  width: 100%;
  .cmsRow {
    display: grid;
    grid-template-rows: 30px 100%;
    padding: 30px;
    .cmsHeading {
      background-color: $prussian_blue;
      border-radius: 20px;
      color: palette('green');
      font-weight: bold;
      padding: 5px;
      position: relative;
      text-align: center;
      text-transform: uppercase;
      width: 100%;
    }
    .cmsBody {
      height: 100%;
      padding: 15px 15px 0;
      position: relative;
      width: 100%;
    }
  }
}
