@import '../../colors.scss';

$today-height: 37vh;
$max-container-height: calc(
  2 * #{$today-height});
$future-height: calc(
  #{$max-container-height} -
  #{$today-height});

.myStablesContainer {
  border-radius: .625rem;
  height: $future-height;
  overflow: scroll;
  .title {
    font-size: .8rem;
    font-weight: bold;
    justify-content: center;
    padding: 1.5rem 0 1rem;
    .today {
      color: palette('green');
    }
    .image {
      height: 1rem;
      padding-right: .938rem;
    }
  }
}

.todayBackground {
  background-color: palette('primary');
  height: $today-height;
}

.maxHeight {
  height: $max-container-height;
}
