@import '../../colors.scss';

.stableList {
  padding: 0 1.5rem;
  .track {
    background-color: palette('background');
    border-radius: 1rem;
    color: palette('green');
    font-weight: bold;
    justify-content: center;
    margin-bottom: .25rem;
    padding: .25rem 0 .5rem;
    text-transform: uppercase;
  }
  .lobby {
    background-color: palette('primary');
  }
  .future {
    background-color: palette('primary');
    color: palette('text');
  }
}
