@import '../../colors.scss';

.stableRow {
  color: palette('text');
  cursor: pointer;
  font-size: .8rem;
  font-weight: bold;
  margin-bottom: .5rem;
  padding: .5rem 0;
  .stableName {
    &::before {
      content: '\2022';
      font-size: 1.7rem;
      line-height: .1rem;
      padding-right: .5rem;
      vertical-align: middle;
    }
  }
  .whiteStableName {
    color: palette('text');
    &::before {
      color: palette('green');
    }
  }
  .yellowStableName {
    color: palette('yellow');
  }
  .redStableName {
    color: palette('error');
  }
  .greenStableName {
    color: palette('green');
  }
  &:focus, &:active, &.selected {
    background-color: palette('background');
    border: .063rem solid palette('green');
    border-radius: 1rem;
    // TODO connect green line to middle column
    // &::after {
    //   content:'';
    //   flex: 1 1;
    //   border-bottom: 2px solid palette('green');
    //   margin: auto -7rem auto;
    // }
  }

  .rank {
    color: palette('green');
    padding: 0 .75rem 0 0;
    text-align: right;
  }

  &.lobby {
    background-color: palette('deepBackground');
    border-radius: 1rem;
    pointer-events: none;
  }
}
