@import '../../colors.scss';

.wrapper {
  color: palette('text');
  text-align: center;
  width: 100%;
  .rowWrapper {
    height: 60px;
    ::placeholder { /* Chrome, Firefox, Opera, Safari 10.1+ */
      color: palette('text');
      opacity: 1; /* Firefox */
    }
    .col {
      padding-left: 5px;
      padding-right: 5px;
    }
  }
  .margin {
    margin-bottom: 10px;
  }
}

.whiteTxt {
  color: palette('text');
  margin-bottom: 10px;
  text-align: center;
}

.formBackground {
  background-color: palette('secondary');
  border-radius: 0;
  border-width: .1px;
}

.errorTxt {
  color: palette('error');
}

i {
  position: absolute;
  right: 5%;
  top: 15%;
  &:hover {
    color: palette('green');
    cursor: pointer;
  }
}
