@import '../colors.scss';

.horse {
  background-image: url('../assets/images/lobby/animated-horse-still.png');
  background-position: bottom;
  background-repeat: no-repeat;
  background-size: contain;
  padding-top: 5%;
  width: 8%;
  &.animated {
    background-image: url('../assets/images/lobby/animated-horse.gif');
  }
}
