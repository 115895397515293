@import '../colors.scss';

.row {
  align-items: center;
  border-bottom: .063rem solid rgba(255, 255, 255, .2);
  margin: 0 2.5rem;
  text-transform: uppercase;
}
.col {
  padding: .313rem 0;
  text-align: center;
}
.colRight {
  border-right: .063rem solid rgba(255, 255, 255, .2);
  display: grid;
  margin: 0 0 .75rem;
  padding: .313rem;
  text-align: right;
}
.colLeft {
  display: grid;
  margin: 0 0 .75rem;
  padding: .313rem;
  text-align: left;
}
.title {
  color: palette('text');
  font-size: 1.125rem;
  font-weight: bold;
  overflow: hidden;
  padding: 0 1.25rem;
  text-overflow: ellipsis;
  white-space: nowrap;
}
.text {
  color: palette('text');
  font-size: .75rem;
}
.boldText {
  color: palette('text');
  font-size: .75rem;
  font-weight: bold;
  text-align: center;
}
.greaterText {
  color: palette('green');
  font-size: .75rem;
  font-weight: bold;
}
.gameStart {
  color: palette('text');
  font-size: 1.25rem;
  text-align: center;
}
.largeNumber {
  color: palette('text');
  font-size: 1.25rem;
  font-weight: bold;
  text-align: center;
}
.largeGreenText {
  color: palette('green');
  font-size: 1.375rem;
  font-weight: bold;
}
.lesserAmount {
  color: palette('text');
  font-size: 1rem;
  padding-top: .625rem;
}
.greaterAmount {
  color: palette('green');
  font-size: 1.375rem;
  font-weight: bold;
}
.enterGameBtn {
  padding: 0 2rem;
  text-align: center;
}
