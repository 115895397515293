.infoHeader {
  font-size: 24px;
  font-weight: bold;
}

.infoBody {
  .subTitle {
    font-size: 20px;
    font-weight: bold;
    margin: 40px 0 0;
  }
  padding: 20px 40px 40px;
}
