@import '../colors.scss';

.navItem {
  align-items: center;
  border-bottom: 4px solid palette('primary');
  color: palette('text');
  display: inline-block;
  font-size: 10px;
  font-weight: bold;
  height: 100%;
  margin: 0;
  padding: 25px 10px;
  white-space: nowrap;
  &:hover {
    color: palette('text');
    text-decoration: none;
  }
  .navImage {
    margin-right: 5px;
  }
}
.navItemActive {
  border-bottom: 4px solid palette('error');
}
@media (max-width: 768px) {
  .navItem {
    width: 118px;
  }
}
