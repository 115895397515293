@import '../../colors.scss';

.raceCardRow {
  border-color: palette('secondary');
  border-style: solid;
  border-width: 1px 0;
  padding: 5px 30px;
  position: relative;
  &.scratched {
    background: repeating-linear-gradient(
      -45deg,
      $dark_grey,
      $dusty_grey 5px,
      palette('background') 5px,
      palette('background') 20px
    );
  }
  .scratchedOverlay {
    background-color: palette('text');
    bottom: 0;
    color: palette('error');
    font-size: 1.5em;
    font-weight: bold;
    height: 100%;
    left: 40%;
    line-height: 70px;
    opacity: .8;
    position: absolute;
    right: 40%;
    text-align: center;
    top: 0;
    width: 20%;
    z-index: 4;
  }
  .disabledOverlay {
    background-color: palette('background');
    bottom: 0;
    height: 100%;
    left: 0;
    opacity: .7;
    position: absolute;
    right: 0;
    top: 0;
    width: 100%;
    z-index: 2;
  }
  &.selected {
    background-color: palette('primary');
  }
  .raceCardInfo {
    cursor: pointer;
    .postPosition {
      font-weight: bold;
      height: 60px;
      .postText {
        margin: 0 auto;
        padding-top: 5px;
        text-align: center;
        width: fit-content;
        .postNumber {
          font-size: 1.5rem;
          line-height: 1.5rem;
        }
      }
    }
    .horseNameWrapper {
      height: 60px;
      line-height: 60px;
      padding-left: 10px;
      .horseName {
        font-weight: bold;
        text-transform: uppercase;
        vertical-align: middle;
      }
    }
    .trainerBlock {
      font-size: .75em;
      line-height: 30px;
      text-align: right;
    }
    .trainerJockey {
      font-size: .75em;
      font-weight: bold;
      line-height: 30px;
    }
    .salaryBlock {
      font-size: .75em;
      line-height: 20px;
      text-align: right;
      .salary {
        font-size: 1.75em;
        font-weight: bold;
        line-height: 40px;
      }
    }
  }
  .selectionWrapper {
    padding: 0 5px;
    .selectionStatus {
      border: 2px solid palette('green');
      border-radius: 50%;
      color: palette('green');
      cursor: pointer;
      font-size: 3em;
      height: 50px;
      line-height: 48px;
      margin: 0 auto;
      position: relative;
      text-align: center;
      width: 50px;
      &:hover {
        background-color: palette('green');
        border-color: palette('background');
        color: palette('background');
      }
      &::after {
        content: '+';
        height: 30px;
        left: 9px;
        position: absolute;
        top: 0;
        width: 15px;
        &:hover {
          border-color: palette('background');
        }
      }
      &.selected {
        border-color: $basic_blue;
        &:hover {
          background-color: palette('secondary');
        }
        &::after {
          border: solid $basic_blue;
          border-width: 0 4px 4px 0;
          content: '';
          height: 30px;
          left: 15px;
          position: absolute;
          top: 6px;
          transform: rotate(45deg);
          width: 15px;
          &:hover {
            border-color: palette('background');
          }
        }
      }
      &.disabled {
        border-color: $dusty_grey;
        color: $dusty_grey;
        cursor: not-allowed;
        display: none;
        &:hover {
          background-color: palette('background');
        }
      }
    }
  }
}
