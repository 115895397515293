@import '../../colors.scss';

.titleRow {
  border-top: 1px solid rgba(255, 255, 255, .2);
  display: grid;
  font-size: .9rem;
  font-weight: bold;
  height: 3rem;
  margin: .625rem 0;
  padding-top: .938rem;
  text-align: center;
  width: 100%;
  .navImage {
    display: inline-block;
    height: 1.125rem;
    margin: .625rem;
    width: auto;
  }
}

.trackNav {
  justify-content: center;
  margin: .625rem .75rem;
  overflow: hidden;
  width: 90%;
  .monthContainer {
    color: palette('text');
    font-size: .8rem;
    font-weight: bold;
    margin: 0;
    outline: 0;
    padding: 0;
    transform: translate(20%, 65%) rotate(270deg);
    transform-origin: center;
    &:focus {
      color: palette('green');
      font-weight: bold;
      outline: 0;
    }
  }
  .colDate {
    margin: 0;
    padding: 0;
    :last-child {
      margin-right: .19rem;
    }
    .navLink {
      color: palette('text');
      display: inline-block;
      font-size: .75rem;
      font-weight: bold;
      margin: 0;
      padding: 0;
      text-align: center;
      width: 100%;
      &:focus {
        background-color: palette('primary');
        box-shadow: inset 0 0 0 2px #f8f9fa9e;
        color: palette('green');
        font-weight: bold;
        outline: 0;
      }
      .labelddFilled {
        font-size: .625rem;
        font-weight: bold;
        justify-content: center;
        margin: .313rem;
        padding: 0;
      }
      .labelDDFilled {
        font-size: 1.1875rem;
        font-weight: normal;
        justify-content: center;
        line-height: 1.25rem;
        margin: 0;
        padding: 0;
        transform: translate(0,-30%);
      }
    }
  }

  .deadNavLink {
    color: palette('text');
    display: inline-block;
    height: 100%;
    pointer-events: none;
    text-align: center;
    width: 100%;
    .labeldd {
      color: $dusty_grey;
      font-size: .625rem;
      font-weight: bold;
      justify-content: center;
      opacity: .5;
    }
    .labelDD {
      color: $dusty_grey;
      font-size: 1.1875rem;
      font-weight: bolder;
      justify-content: center;
      opacity: .5;
    }
  }
}

.tabContent {
  height: 14.375rem;
  margin: 0 auto;
  overflow-y: scroll;
  width: 100%;
  .noGame {
    font-size: 1.313rem;
    font-weight: bold;
    padding-top: 1.875rem;
    text-align: center;
    width: 100%;
  }
}

.loader {
  margin: 0 45%;
  position: relative;
}
