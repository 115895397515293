@import '../colors.scss';

.StablePointsProgress {
  padding: .75rem;
  width: 100%;
  .topRow {
    padding-bottom: .75rem;
    .contentCell {
      .pointsWrapper {
        padding: 10%;
        padding-left: 20%;
        .stablePoints {
          .pointTotal {
            font-size: 2vw;
            font-weight: bold;
          }
          .pointsLabel {
            font-size: 1vw;
          }
        }
        .rank {
          line-height: 1.7rem;
          padding-top: .625rem;
          .userRank {
            align-self: flex-end;
            font-size: 1.7vw;
            font-weight: bold;
            padding-bottom: .125rem;
            padding-right: .125rem;
          }
          .rankText {
            align-self: flex-end;
          }
        }
      }
      .trackWrapper {
        margin: 20% auto;
      }
      .trophyWrapper {

        margin: 20% auto;
        width: 50%;
      }
    }
  }
}
