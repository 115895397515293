@import '../colors.scss';

.loaderWrapper {
  display: inline-block;
  width: 100%;
  .loaderImage {
    bottom: 0;
    box-sizing: border-box;
    left: 0;
    margin: auto;
    position: absolute;
    right: 0;
    top: 0;
    width: 8%;
  }
}
