@import '../colors.scss';

.btn {
  background-color: palette('green');
  border: .125rem solid palette('green');
  border-radius: 3.125rem;
  box-shadow: none;
  color: palette('primary');
  cursor: pointer;
  padding: .625rem 1.25rem;
  width: 9.375rem;
  &:disabled {
    opacity: .4;
  }
  &:focus {
    outline: 0;
  }
  &:hover {
    color: palette('text');
  }
  &.outline {
    background-color: palette('primary');
    border: .125rem solid palette('green');
    color: palette('green');
    &:hover {
      background-color: palette('green');
      color: palette('primary');
    }
  }
  &.large {
    width: 100%;
  }
  &.outline-large {
    background-color: palette('primary');
    border: .125rem solid palette('green');
    color: palette('green');
    width: 100%;
    &:hover {
      background-color: palette('green');
      color: palette('primary');
    }
  }
  &.whiteOutlineSkinny {
    background-color: palette('primary');
    border: .063rem solid rgba(255, 255, 255, .4);
    color: palette('text');
    font-size: .75rem;
    font-weight: bold;
    padding: .313rem;
    width: 80%;
    &:hover {
      background-color: palette('text');
      color: palette('primary');
    }
  }
  &.greenSkinny {
    border: .063rem solid palette('green');
    font-size: .75rem;
    font-weight: bold;
    padding: .313rem;
    width: 80%;
  }
  &.redOutline {
    background-color: palette('text');
    border: .063rem solid palette('error');
    font-size: .75rem;
    font-weight: bold;
    padding: .313rem;
    width: 100%;
    &:hover {
      background-color: palette('error');
    }
  }
  &.black {
    background-color: $basic_black;
    border: .063rem solid $basic_black;
    color: $basic_white;
    font-size: .75rem;
    font-weight: bold;
    padding: .313rem;
    width: 100%;
    &:hover {
      background-color: $basic_white;
      color: $basic_black;
    }
  }
  &.redOutlineBlue {
    background-color: palette('deepBackground');
    border: .063rem solid palette('error');
    color: palette('text');
    font-size: .75rem;
    margin: .313rem;
    white-space: nowrap;
    width: 100%;
    &:hover {
      background-color: palette('error');
    }
  }
}
