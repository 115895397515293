@import '../../colors.scss';

.detailHeader {
  border-bottom: none !important;
}

.detailBody {
  font-size: .8em;
  padding: 1.875rem 3.75rem;
  .detailTopRow {
    padding-bottom: 1.875rem;
  }
  .postPosition {
    display: inline-block;
    font-weight: bold;
    height: 3.75rem;
    position: relative;
    width: 3.75rem;
    .postText {
      margin: 0 auto;
      padding-top: .313rem;
      text-align: center;
      width: fit-content;
      .postNumber {
        font-size: 2rem;
        line-height: 2rem;
      }
    }
  }
  .detailHorseName {
    padding-top: 1.25rem;
  }
  .detailShort {
    height: 15%;
    justify-content: center;
  }
  .detailRaces {
    font-size: 1rem;
    font-weight: bold;
    margin: 0 .9375rem .313rem;
  }
  .detailBold {
    font-weight: bold;
  }
  .detailItalic {
    font-style: italic;
    padding-right: .625rem;
  }
  .detailGreen {
    color: palette('green');
    font-size: 1.75rem;
    font-weight: bold;
  }
  .detailCentered {
    justify-content: center;
  }
  .detailFollow {
    color: palette('green');
    font-weight: bold;
  }
  .workouts {
    display: contents;
    font-size: .9em;
  }
  .ownershipRow {
    padding-top: 1.5625rem;
  }
  .infoCircle {
    cursor: pointer;
    margin: .188rem 0 0 .188rem;
  }
}
