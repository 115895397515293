@import '../variables.scss';
@import '../colors.scss';

.SDfooter {
  color: palette('text');
  font-size: 8px;
  width: 100%;
  .nav {
    justify-content: center;
    padding-top: 5px;
    a {
      color: palette('text');
      padding: 0 15px;
      text-decoration: none;
    }
    a:hover {
      color: palette('text');
      text-decoration: none;
    }
    .logout {
      cursor: pointer;
    }
  }
}
