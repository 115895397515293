.wrapper {
  background-image: url('../assets/images/stableduel-background-1440x1080.jpg');
  background-size: cover;
  left: 0;
  min-height: 100%;
  min-width: 100%;
  overflow: hidden;
  position: absolute;
  text-align: center;
  top: 0;
  .video {
    bottom: 0;
    min-height: 100%;
    min-width: 100%;
    position: fixed;
    right: 0;
  }
  .shadow {
    background-color: rgba(20, 29, 109, .3);
    left: 0;
    min-height: 100%;
    min-width: 100%;
    position: absolute;
    top: 0;
  }
}
