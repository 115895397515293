.modal-content {
  border-radius: 1.25rem;
  color: $downriver;
  padding: 1.25rem;
}

.modal-header {
  border-bottom: .125rem solid $dusty_grey;
}

.modal-header .close {
  font-size: 3em;
  font-weight: 200;
  line-height: 0;
  padding: .625rem 0;
}

.modal-header .close:focus {
  outline: none;
}

.modal-title {
  width: 100%;
}
