@import '../../colors.scss';
@import '../../variables.scss';

.wrapper {
  .rowOne {
    border-bottom: 2px solid rgba(255, 255, 255, .2);
    bottom: 0;
    color: palette('text');
    font-size: .75rem;
    font-weight: bold;
    justify-content: center;
    overflow: hidden;
    text-overflow: ellipsis;
    vertical-align: bottom;
    white-space: nowrap;
    .gameName {
      margin: .313rem;
    }
  }
  .rowTwo {
    margin-bottom: .75rem;
    .colOne {
      padding: 0;
      .stables {
        color: palette('text');
        font-size: .5rem;
        font-weight: bold;
        margin: .875rem 0 .313rem;
        text-transform: uppercase;
        .largeNumber {
          margin-left: 1.25rem;
        }
      }
      .currentPool {
        color: palette('green');
        font-size: .5rem;
        font-weight: bold;
        margin: 0;
        .currentAmt {
          margin-left: 1.25rem;
        }
      }
    }
    .colTwo {
      align-content: center;
      font-size: .5rem;
      font-weight: bold;
      margin: 1.25rem auto;
      padding: 0;
    }
  }
}
