@import '../colors.scss';

.stableList {
  .scratched {
    opacity: .6;
  }
  .stableListRow {
    cursor: pointer;
    display: flex;
    padding: .625rem .625rem .625rem 2.75rem;
    position: relative;
    .scratchedOverlay {
      background-color: rgba(9,27,64,.6);
      bottom: 0;
      color: $yellow;
      font-size: 1.25rem;
      font-weight: bold;
      height: 100%;
      left: 40%;
      line-height: 3.625rem;
      position: absolute;
      right: 40%;
      text-align: center;
      top: 0;
      width: 20%;
      z-index: 2;
    }
    .raceCardInfo {
      display: contents;
      .postPosition {
        display: inline-block;
        font-weight: bold;
        height: 2.5rem;
        position: relative;
        width: 2.5rem;
        .postText {
          font-size: .688rem;
          margin: 0 auto;
          padding-top: .125rem;
          text-align: center;
          width: fit-content;
          .postNumber {
            font-size: 1.25rem;
            line-height: 1.25rem;
          }
        }
      }
    }
    .displayName {
      align-self: center;
      display: flex;
      padding-left: 1.25rem;
      text-transform: uppercase;
      vertical-align: middle;
      .horseName {
        color: palette('text');
        font-weight: bold;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
      }
      .selectName {
        color: $dim-light-blue;
        font-style: italic;
        justify-content: center;
      }
    }
    .finalPoints {
      color: palette('text');
      font-weight: bold;
      margin-bottom: 0;
      margin-top: 8px;
      text-align: end;
      &.positive {
        color: palette('green');
      }
      &.negative {
        color: palette('error');
      }
    }
    .selectionWrapper {
      .selectionStatus {
        border-radius: 50%;
        cursor: pointer;
        font-size: 3em;
        height: 2.5rem;
        line-height: 2.5rem;
        margin: 0 auto;
        position: relative;
        text-align: center;
        width: 2.5rem;
        &.notSelected {
          border: .094rem solid palette('error');
          border-color: palette('error');
          color: palette('error');
          &::after {
            content: '_';
            height: 1.875rem;
            left: .438rem;
            position: absolute;
            top: -1.188rem;
            width: .938rem;
          }
        }
      }
    }
  }
}
