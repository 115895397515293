@import '../colors.scss';

.progressWrapper {
  padding: 2%;
  padding-top: 10%;
  position: relative;
  width: 96%;

  .progressBar {
    bottom: 52%;
    height: fit-content;
    position: absolute;
    width: 100%;
    z-index: 10;
      
    
    .progressDiv {
      background-color: transparentize(palette('text'), .1);
    }
    .progress {
      background-color: $basic_blue;
      height: .1rem;
    }
  }
  .trophyRow {
    height: 100%;
    position: absolute;
    top: 0;
    width: 100%;

    .trophy {
      height: 100%;
      position: absolute;
      width: auto;

      &.silver {
        left: 7.5%;
      }
      &.gold {
        left: 27.5%;
      }
      &.platinum {
        left: 47.5%;
      }
      &.diamond {
        left: 67.5%;
      }
      &.vip {
        left: 87.5%;
      }
      &.notAchieved {
        filter: alpha(opacity=40);
        opacity: .4;
        zoom: 1;
      }
    }
  }
}
