@import '../../colors.scss';

.wrapper {
  color: palette('text');
  margin: 0 0 20px;
  text-align: center;
  width: 100%;
  .passTxt {
    margin: 0 0 10px;
    .greenTxt {
      color: palette('green');
    }
    .returnLogin {
      margin: 30px auto;
    }
  }
  .resendEmail {
    background: none;
    border: none;
    color: palette('text');
    cursor: pointer;
    text-decoration: underline;
  }

  .rowWrapper {
    height: 50px;
    ::placeholder { /* Chrome, Firefox, Opera, Safari 10.1+ */
      color: palette('text');
      opacity: 1; /* Firefox */
    }
    .col {
      padding-left: 5px;
      padding-right: 5px;
    }
  }
  .margin {
    margin-bottom: 10px;
  }
  .forgetPass {
    cursor: pointer;
  }
}

.fpRow {
  .fpIcon {
    padding: 0;
    .linkTxt {
      color: palette('text');
      font-size: 20px;
    }
  }
}

i {
  position: absolute;
  right: 5%;
  top: 15%;
}

i:hover {
  color: palette('green');
  cursor: pointer;
}
