@import '../colors.scss';

.trackName {
  background-color: palette('primary');
  border: none;
  border-radius: 20px;
  box-shadow: none;
  color: palette('green');
  font-weight: bold;
  margin: 20px 20px 10px;
  padding: 5px 50px;
  text-align: left;
  text-transform: uppercase;
  width: 98%;
}
