@import '../../colors.scss';
@import '../../variables.scss';

.leaderboard {
  color: palette('text');
  font-size: .75rem;
  font-weight: bold;
  width: 100%;
  .notStarted {
    background-color: palette('deepBackground');
  }
  @media (max-width: 992px) {
    font-size: 1rem;
  }
  .divWrapper {
    background-color: palette('darker_primary');
    border-radius: 1.8rem 1.8rem 0 0;
    .myStableInfo {
      background-color: $prussian_blue;
      border-radius: 1.875rem;
      color: palette('green');
      height: 3.125rem;
      overflow: hidden;
      text-overflow: ellipsis;
      text-transform: uppercase;
      white-space: nowrap;
      .blankRank {
        color: palette('text');
        margin-top: .125rem;
        text-align: center;
      }
      .myRank {
        margin-top: .125rem;
        padding-left: 1.8rem;
      }
      .image {
        height: 1.25rem;
        @media (max-width: 992px) {
          height: 1.5rem;
        }
      }
      .myStableName {
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
      }
      .myPoints {
        color: palette('text');
      }
    }
  }
  .noBackground {
    background-color: palette('background');
  }
  .tableHeadings {
    font-size: .5rem;
    white-space: nowrap;
    @media (max-width: 992px) {
      font-size: .75rem;
    }
  }
  .boardObjects {
    overflow-y: scroll;
    .userRow {
      margin: .313rem;
      .rank {
        padding-left: 2.1rem;
      }
      &:nth-child(-n + 3) {
        .rank {
          color: palette('green');
          padding-left: 2.1rem;
        }
        .topThree {
          color: palette('primary');
        }
      }
      .stableName {
        font-size: .7rem;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
        @media (max-width: 992px) {
          font-size: .9rem;
        }
      }
      .boardPoints {
        font-weight: normal;
      }
    }
    .myStable {
      background-color: palette('green');
      border-radius: 1.875rem;
      color: palette('primary');
    }
    .futureContainer {
      background-color: palette('darker_primary');
      border-radius: 0 0 1.25rem 1.25rem;
      padding: 2.5rem;
      .futureGameTxt {
        color: palette('text');
      }
      .futureGameDays {
        color: palette('green');
      }
    }
  }
}
